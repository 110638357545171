export default function config() {
  const ACCESS_ABILITY_ACTIVITY_LOG = { action: 'change', subject: 'Activity Log' }
  // Table Handlers
  const tableColumns = [
    { key: 'event', label: 'Activity' },
    { key: 'created_time', label: 'Date' },
    { key: 'created_hour', label: 'Time' },
    { key: 'auditable_type', label: 'Type' },
    { key: 'user.username', label: 'User' },
  ]

  return {
    tableColumns,
    ACCESS_ABILITY_ACTIVITY_LOG,
  }
}
