var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"body-top"}},[_c('customer-name',{attrs:{"title":""}})],1),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"is-print":true,"table-config-options":{
      data: {
        user_id: _vm.$route.params.id,
      },
    },"can-create":_vm.ACCESS_ABILITY_ACTIVITY_LOG},scopedSlots:_vm._u([{key:"cell(created_time)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(created_at)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.convertTZ(data.value))+" ")]}},{key:"cell(event)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(text)",fn:function(ref){
    var data = ref.data;
return [_c('b-link',{attrs:{"to":_vm.renderWithLink(data.item)}},[_vm._v(" "+_vm._s(data.value)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }