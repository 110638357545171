import crudActions from '@/libs/storeConstActions'
import store from '@/store'

const endpoint = () => `customers/${store.state.customers?.customer?.id}/audits`

const Actions = crudActions(endpoint)

export default {
  ...Actions,
}
