<template>
  <div>
    <portal to="body-top">
      <customer-name title="" />
    </portal>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :is-print="true"
      :table-config-options="{
        data: {
          user_id: $route.params.id,
        },
      }"
      :can-create="ACCESS_ABILITY_ACTIVITY_LOG"
    >
      <!--      :table-config-options="getUserAudit"-->
      <template #cell(created_time)="{ data }">
        {{ data.value }}
      </template>

      <template #cell(created_at)="{ data }">
        {{ convertTZ(data.value) }}
      </template>

      <template #cell(event)="{ data }">
        {{ data.value }}
      </template>

      <template #cell(text)="{ data }">
        <b-link :to="renderWithLink(data.item)">
          {{ data.value }}
        </b-link>
      </template>
    </l-table>
  </div>

</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
import tableConfig from '@/views/main/customers/view/activity-log/activityLogConfig'
import { BLink } from 'bootstrap-vue'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import activityLogModule from '@/store/main/customer/activityLog'
import { onUnmounted } from '@vue/composition-api'
import CustomerName from '../../components/CustomerName.vue'

export default {
  name: 'ActivityLog',
  components: {
    CustomerName,
    LTable,
    BLink,
  },
  computed: {
    audits() {
      return this.$store.state[this.MODULE_NAME].audits
    },
  },
  created() {
    this.$emit('updateMenu', 'home-customers-activity-log')
  },
  setup() {
    const MODULE_NAME = 'audits'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, activityLogModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    const { tableColumns, ACCESS_ABILITY_ACTIVITY_LOG } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
      ACCESS_ABILITY_ACTIVITY_LOG,
    }
  },
}
</script>
